import React, { useState, useRef, useEffect } from "react";
import Header from "./header";
import Footer from "./footer";
import bgimagedots from '../assets/img/bg-image_blue_main.png';
import jobtypeimg from '../assets/img/jobb-type.png';
import PlaceIcon from '@mui/icons-material/Place';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import experienceimg from '../assets/img/experience.png';
import qulifimg from '../assets/img/qualificaion.png';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import GroupIcon from '@mui/icons-material/Group';
import EastIcon from '@mui/icons-material/East';
import StickyBox from "react-sticky-box";
import {
    Link,
    BrowserRouter,
    Routes,
    Route,
    Navigate,
    useParams,
    useNavigate
} from "react-router-dom";

import { useSelector } from "react-redux";

import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import FormControl from '@mui/material/FormControl';
import {
    addApplication,

} from "../actions/pagecontent.actions";
import { updateNullOfObjectValues, isEmail, isPhoneNumber } from '../utils/helper';
import ReCAPTCHA from "react-google-recaptcha";

import { isComingSoon, generateUniqueToken } from "../utils/helper";

import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteIcon from "@mui/icons-material/Delete";
import classNames from "classnames";
import secureLocalStorage from "react-secure-storage";
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
function dateformat(datwene) {
    const inputDate = datwene.split("-");;
     console.log(inputDate);

    const date = new Date(Date.UTC(inputDate[0], (parseInt(inputDate[1])-1), inputDate[2]));

    const formatter = new Intl.DateTimeFormat('en-GB', {
       
        day: '2-digit',
        month: 'short',
        year: 'numeric',
        timeZone: 'Asia/Kolkata', // Ensure UTC formatting
    });


    console.log(formatter.format(date));
    const formatteNr = formatter.format(date).split(" ");;
    const formattedDate = formatteNr[0] + ' ' + formatteNr[1] + ', ' + formatteNr[2];
    return formattedDate;

}
function cahngenumber(number) {

    const twoDigitNumber = number.toString().padStart(2, '0');
    return twoDigitNumber;

}

const Jobdetails = () => {
    const { careerData } = useSelector((state) => state.pageContents);
    console.log(careerData);
    const [allprodata, setAllprodata] = useState(null);
    const [slugname, setSlugnam] = useState(null);
    const imageFileInputRef = React.createRef();
    const [imageFile, setImageFile] = useState(null);
    // console.log(productData);
    let navigate = useNavigate();
    const { slug } = useParams();
    // console.log(slug);
    useEffect(() => {
        console.log(slug);
        if (careerData.career.length > 0) {
            var proal = careerData.career;
            var res = proal.map((item, indexc) => {
                if (item.slug == slug) {
                    console.log(item);
                    setAllprodata(item);
                }


            });
        };



    }, []);
    useEffect(() => {
        console.log(slug);
        if (careerData.career.length > 0) {
            var proal = careerData.career;
            var res = proal.map((item, indexc) => {
                if (item.slug == slugname) {
                    console.log(item);
                    setAllprodata(item);
                    navigate(`/jobdetails/${slugname}`);
                }


            });
        };



    }, [slugname]);

    const handleDialogOffChange = (isOpen) => {
        setIsOpen(false)
    }

    const [isOpen, setIsOpen] = useState(false)

    const handleDialogChange = (isOpen) => {
        setIsOpen(isOpen);

        var casedeat = { ...formValues };

        casedeat.career_pos = allprodata.title;
        casedeat.career_id = allprodata._id;
        casedeat.email = '';
        casedeat.phone = '';
        casedeat.name = '';
        casedeat.dob ='2025-02-20';
        casedeat.resume = '';

        casedeat.dob_new = null

        console.log(casedeat);
        setFormValues(casedeat);
        setFormErrors(updateNullOfObjectValues(formFields));
        setErrorMessage('');
        setSuccessMessage('');



    }



    const recaptchaRef = React.useRef();

    const [pageselect, setPageselect] = React.useState('');
    React.useEffect(() => {
        setTimeout(() => setLoader(false), 1500);
        console.log('generateUniqueToken', generateUniqueToken());
    }, []);
    const [opencaseyes, setOpenCaseyes] = React.useState(false);


    const handleClosecaseyes = () => {
        setOpenCaseyes(false);
    };



    const formFields = {
        name: '',
        email: '',
        phone: '',
        career_id: '',
        career_pos: '',
        resume: '',
        dob: '2025-02-20',
        dob_new: null


    }
    const [loader, setLoader] = React.useState(true);

    const { ip_address } = useSelector((state) => state.userdata);
    const [formValues, setFormValues] = React.useState(formFields);
    const [formErrors, setFormErrors] = React.useState(updateNullOfObjectValues(formFields));
    const [submitting, setSubmitting] = React.useState(false);
    const [successMessage, setSuccessMessage] = React.useState('');
    const [errorMessage, setErrorMessage] = React.useState('');
    const [otpid, setotpid] = React.useState(null);

    const [openotp, setOpenotp] = React.useState(false);


    const handleClickOpenotp = () => {
        setOpenotp(true);
    };
    const handleCloseotp = () => {
        setOpenotp(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        console.log(formValues);
        setFormValues({ ...formValues, [name]: value });
        setFormErrors({ ...formErrors, [name]: null });
    }
    const handleImageDragOver = (event) => {
        event.preventDefault();
        event.stopPropagation();
    };

    const handleImageDrop = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const droppedFiles = Array.from(event.dataTransfer.files);
        handleImageFiles(droppedFiles);
    };

    const handleImageFileChange = (event) => {
        let files = Array.from(event.target.files);
        handleImageFiles(files);
    };

    const handleImageFiles = (files) => {
        files = files.filter((file) => file.name.match(/\.(pdf|doc|docx)$/));
        if (files.length) {
            setImageFile(files[0]);
            let temp = { ...formValues }
            temp.resume = files[0].name;
            setFormValues(temp);
            setFormErrors({ ...formErrors, resume: "" });
        }
    }

    const handleImageFileRemove = () => {
        setImageFile(null);
        if (imageFileInputRef.current) {
            imageFileInputRef.current.value = "";
        }
    };
    const handleClickOpenCaseyes = () => {
        setFormValues(formFields);
        //    recaptchaRef.current.reset();
        setFormErrors(updateNullOfObjectValues(formFields));
        setErrorMessage('');
        setSuccessMessage('');
        setOpenCaseyes(true);

    };

    const [opendate, setOpenDate] = useState(false); // State to control the open/close
    const handleIconClick = () => {
        setOpenDate(true); // Open the picker when the icon is clicked
    };

    const handleDate = (e) => {
        let temp = { ...formValues }

        var d = new Date(e);
        var date;
        var month;
        if (d.getDate() < 10) {
            date = "0" + d.getDate();
        }
        else {
            date = d.getDate();
        }

        if ((d.getMonth() + 1) < 10) {
            month = "0" + (d.getMonth() + 1);
        }
        else {
            month = (d.getMonth() + 1);
        }
        var datestring = d.getFullYear() + "-" + month + "-" + date;
        temp.dob = datestring;
        temp.dob_new = e;

        console.log(datestring);
        setFormErrors({ ...formErrors, ['dob']: null });
        setFormValues(temp);
        setOpenDate(false);
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        setSubmitting(false);

        console.log(validateForm());
        if (validateForm()) {
            setSubmitting(true);
            setErrorMessage('');
            setSuccessMessage('');
            let formData = new FormData();
        
              formData.append("name", formValues.name);
            
            formData.append("email", formValues.email);
            formData.append("phone", formValues.phone);
            formData.append("career_id", formValues.career_id);
            formData.append("career_pos", formValues.career_pos);
            formData.append("dob", formValues.dob);
              formData.append("resume", imageFile);
          
            // let formData = { ...formValues };
            formData.recaptcha = recaptchaRef.current.getValue();
            setPageselect(formData.pagename);
            secureLocalStorage.setItem('selectoption', formData);
            addApplication(formData)
                .then((response) => {
                    setSubmitting(false);
                    if (response.data.status) {
                        setotpid(response.data.lastid);
                        //setSuccessMessage(response.data.message);
                        setFormValues(formFields);
                        recaptchaRef.current.reset();
                        setOpenCaseyes(false);

                        setIsOpen(false);
                        // setOpenthank(true);
                        navigate('/thankyou/career');
                        setTimeout(() => {
                          
                            // setOpenthank(false);
                        }, 3000);


                    } else {
                        setErrorMessage(response.data.message);
                    }
                })
                .catch((error) => {
                    setSubmitting(false);
                    setErrorMessage('Something went wrong. Please try again later.');
                });
        }
    }
    const [openthank, setOpenthank] = React.useState(false);
    const handleClickOpenthank = () => {

        setOpenthank(true);
    };
    const handleClosethank = () => {
        setOpenthank(false);
    };
    const validateForm = () => {
        let isValid = true;
        let errors = {};
        Object.keys(formValues).forEach((key) => {
            if (!formValues[key] && key !== 'recaptcha' && key !== 'dob_new'
            ) {
                isValid = false;
                errors[key] = 'This field is required';
            } else if (key === 'email' && !isEmail(formValues[key])) {
                isValid = false;
                errors[key] = 'Invalid email address';
            }
            else if (key === 'phone' && !isPhoneNumber(formValues[key])) {
                isValid = false;
                errors[key] = 'Invalid phone number';
            }

        });
        const recaptchaValue = recaptchaRef.current.getValue();
        if (!recaptchaValue) {
            isValid = false;
            errors.recaptcha = 'Please verify captcha';
        }
        console.log(errors);
        setFormErrors(errors);
        return isValid;
    }


    return (
        <>
            <div className="main-bg-sec">
                <Header />
                <div className="main-sec main-innerbanner main-detsila-two">
                    <div className="container">
                        <div className="col-lg-12">
                            <div className="banner-sec-content main-banner-sec main-bannerinner">
                                {allprodata != null &&
                                    <h1>{allprodata.banner_title} </h1>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/* job details section start */}
            <div className="job-details about-innov-main ">


                <div className="job-desc-inner">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="vision-image-bg sev-main-bg">
                                    <div className="bg-icon-belowservicess serv-bg-iconrigt serv-dot-bg job-detl-dots">
                                        <img src={bgimagedots} alt="bg-icon" />
                                    </div>
                                </div>
                                <StickyBox offsetTop={20} offsetBottom={20}>
                                    <div className="job-desc-left">
                                        <h2>Job Information</h2>

                                        <div className="job-inform-below">
                                            {allprodata != null &&
                                                <>
                                                    <ul>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-job">
                                                                    <img src={jobtypeimg} />
                                                                </div>
                                                                <div className="info-text ">
                                                                    <h3>Job Type</h3>
                                                                    <p>{allprodata.title}</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-loc">
                                                                    <PlaceIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Location</h3>
                                                                    <p>{allprodata.location}</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-date">
                                                                    <CalendarMonthIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Date Posted</h3>
                                                                    <p>{dateformat(allprodata.date)}</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-experience">
                                                                    <img src={experienceimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Experience</h3>
                                                                    <p>{allprodata.experience}</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-quali">
                                                                    <img src={qulifimg} />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Qualification</h3>
                                                                    <p>{allprodata.qualification}</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-emply">
                                                                    <HowToRegIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>Employee Type</h3>
                                                                    <p>{allprodata.emp_type}</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                        <li>
                                                            <div className="info-icon">
                                                                <div className="info-img info-vac">
                                                                    <GroupIcon />
                                                                </div>
                                                                <div className="info-text">
                                                                    <h3>No of vacancies</h3>
                                                                    <p> {cahngenumber(allprodata.no_vac)}</p>
                                                                </div>

                                                            </div>
                                                        </li>
                                                    </ul>
                                                </>
                                            }
                                        </div>
                                    </div>
                                </StickyBox>
                            </div>
                            <div className="col-md-7">
                                <div className="job-desc-right">
                                    <h2>Job Description</h2>
                                    {allprodata != null &&
                                        <>
                                            <div dangerouslySetInnerHTML={{ __html: allprodata.job_desc }}></div>
                                        </>
                                    }
                                    <div className="detail-apply-sec">
                                        <div className="detail-inn">
                                            {/* {slug != 'laravel-developer'  ?
                                            <> */}
                                                {allprodata != null &&
                                                <>
                                                    <p>Apply at <span>{allprodata.apply_mail}</span></p>

                                                </>
                                            }
                                            <p className="or-sec">OR</p>

                                            <div className="button-sec btn-align btn-learn btn-job-aply">
                                                <a href="#" class="btn-inner-sec btn-inner-lern" onClick={handleDialogChange}>
                                                    <span className="btn-name">Apply Now</span>
                                                    <span className="btn-icon"><EastIcon /></span>
                                                </a>
                                            </div>
                                            {/* </>
                                         
                                            
                                            :
                                            <p><span>Hiring is on Hold</span></p> 
                                            }
                                            */}

                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>


                </div>
                <div className="bg-icon-belowprojt">
                    <img src={bgimagedots} alt="bg-icon" />
                </div>




            </div>
            {/* job etails section end  */}
            <Footer />


            <BootstrapDialog
                onClose={handleDialogOffChange}
                aria-labelledby="customized-dialog-title"
                open={isOpen} className="prodt-modl-news job-detal-sec"
            >

                <IconButton
                    aria-label="close"
                    onClick={handleDialogOffChange}
                    sx={(theme) => ({
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: theme.palette.grey[500],
                    })}
                    className="closebew-icon"
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <h2>Apply Now</h2>
                    <div className="form-sec-main">






                        <div className='form-sec form-modal'>
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1, width: '100%' },
                                }}
                                noValidate
                                autoComplete="off"
                            >

                                <TextField id="standard-basic" label="Your Full Name" variant="outlined"

                                    name="name"
                                    className={classNames("form-control", { 'is-invalid': formErrors.name })}
                                    onChange={handleInputChange}
                                    style={{ backgroundImage: 'none' }}
                                    value={formValues.name}


                                />
                                {
                                    formErrors.name &&
                                    <div className="invalid-feedback">
                                        <div className='error-msg'>
                                            {formErrors.name}
                                        </div>
                                    </div>
                                }
                            </Box>
                        </div>



                        <div className='form-sec form-modal'>
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1, width: '100%' },
                                }}
                                noValidate
                                autoComplete="off"
                            >

                                <TextField id="standard-basic" label="Your Email Id" variant="outlined"
                                    name="email"
                                    className={classNames("form-control", { 'is-invalid': formErrors.email })}
                                    onChange={handleInputChange}
                                    style={{ backgroundImage: 'none' }}
                                    value={formValues.email}

                                />
                                {
                                    formErrors.email &&
                                    <div className="invalid-feedback">
                                        <div className='error-msg'>
                                            {formErrors.email}
                                        </div>
                                    </div>
                                }
                            </Box>
                            <div className="email-verify-sec">
                                <p>This email will be verified</p>
                            </div>


                        </div>
                        {/* <div className="row">
                            <div className="col-md-6"> */}
                        <div className='form-sec form-modal'>
                            <Box
                                component="form"
                                sx={{
                                    '& > :not(style)': { m: 1, width: '100%' },
                                }}
                                noValidate
                                autoComplete="off"
                            >

                                <TextField id="standard-basic" label="Your Mobile Number" variant="outlined"

                                    name="phone"
                                    className={classNames("form-control", { 'is-invalid': formErrors.phone })}
                                    onChange={handleInputChange}
                                    style={{ backgroundImage: 'none' }}
                                    value={formValues.phone}
                                />
                                {
                                    formErrors.phone &&
                                    <div className="invalid-feedback">
                                        <div className='error-msg'>
                                            {formErrors.phone}
                                        </div>
                                    </div>
                                }
                                {/* <div className="email-verify-sec contact-veri">
                                    <p style={{top:'7px'}}>This number will be verified</p>
                                </div> */}
                            </Box>



                            <div>
                                <p class="content-tol"><span class="tooltipss home-tool" data-content="In case we need to contact you" tabindex="0"><i class="fa-solid fa-info-circle"></i></span></p>
                            </div>
                        </div>
                        {/* </div> */}

                        {/* <div className="col-md-6">
                        <div className='form-sec form-modal date-pick-sec-modl new-modl-calen' >
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                    components={[
                                        'MobileDatePicker',
                                    ]}
                                >

                                    <DemoItem >
                                        <MobileDatePicker
                                            label=" Date"
                                            inputFormat="MM/dd/yyyy"
                                            value={formValues.dob_new}
                                            open={opendate} // Control the open state of the picker
                                            onClose={() => setOpenDate(false)}
                                            onOpen={() => setOpenDate(true)} // Close the picker when it opens via text field

                                            onChange={handleDate}
                                        />
                                        <span className="new-calen-icons"><CalendarMonthIcon /></span>
                                    </DemoItem>

                                </DemoContainer>
                            </LocalizationProvider>
                            {
                                formErrors.dob &&
                                <div className="invalid-feedbackcontact">
                                    <div className='error-msg'>
                                        {formErrors.dob}
                                    </div>
                                </div>
                            }
                        </div>

</div> */}
                        {/* </div> */}

                        <div className='form-sec form-modal'>
                            <div className="uplad-sec-new">
                                <h2>Upload Resume</h2>
                            </div>
                            {/* <div
                                className=""
                                onDragOver={handleImageDragOver}
                                onDrop={handleImageDrop}
                            >
                                <div className="formbold-file-input">
                                    <CloudUploadIcon />
                                    <p className="">
                                        {" "}
                                        Drag and upload Resume File <br />

                                        <small className="">
                                            (Supported files types: PDF, DOC, DOCX)
                                        </small>
                                    </p>

                                    <input
                                        className=""
                                        type="file"
                                        accept=".pdf,.doc,.docx"
                                        ref={imageFileInputRef}
                                        onChange={handleImageFileChange} name="file"
                                    />
                                </div>
                            </div> */}
                            <div
                                className=""
                                onDragOver={handleImageDragOver}
                                onDrop={handleImageDrop}
                            >
                                <div class=" formbold-file-input">
                                
                                    <label for="file">
                                        <div className="uplod-inner">
                                        <CloudUploadIcon />
                                            <span class="formbold-drop-file"> Drag and upload Resume File </span>
                                            <p className="suprt-sec">
                                                (Supported files types: PDF, DOC, DOCX)
                                            </p>
                                        </div>
                                    </label>
                                    <input 
                                        type="file"
                                        accept=".pdf,.doc,.docx"
                                        ref={imageFileInputRef}
                                        onChange={handleImageFileChange} name="file" id="file"/>
                
                                </div>
                            </div>


                            {imageFile ? (
                                <div className="file-del-new new-file-del">
                                    <p>{imageFile.name}</p>
                                    <span>
                                    <CloseIcon
                                        className=""
                                        onClick={handleImageFileRemove}
                                    />
                                      </span>
                                </div>
                              
                            ) : (
                                <>
                                    {formErrors.resume && (
                                        <div className="invalid-feedbackcontact">
                                        <div className='error-msg error-newss'>
                                            {formErrors.resume}
                                        </div>
                                    </div>
                                    )}
                                </>
                            )}

                        </div>
                    
                       



                    <div className='form-sec form-modal'>

                        <p className="case-privacy">Your information is secured with us, please read our <Link to='/privacy-policy' className="case-link-prv">privacy policy</Link> </p>
                    </div>
                    <div className='form-sec form-modal' >


                        <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY} className='recaptcha-sec'
                        />
                        {
                            formErrors.recaptcha &&
                            <div className="invalid-feedback" style={{ display: 'block' }}>
                                <div className='error-msg captch-error'>
                                    {formErrors.recaptcha}
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className="button-sec btn-align mdl-btn new-mdl-btn">
                    <a href="javascript:void(0)" class="btn-inner-sec" onClick={handleSubmit}>
                        <span className="btn-name btn-sub">Submit   {submitting && <div className="spinner-border spinner-border-sm loader-left" role="status">
                            <span className="visually-hidden">Loading...</span>
                        </div>}</span>
                        <span className="btn-icon"><EastIcon /></span>
                    </a>
                </div>

                <div className='validate-sec validate-sec-mode'>
                    {
                        successMessage &&
                        <div className="success-msg-sec">
                            <p className="succ-msg">{successMessage}
                            </p>
                        </div>
                    }
                    {
                        errorMessage &&
                        <div className="error-msg-sec">
                            <p className="error-msg">{errorMessage}
                            </p>
                        </div>
                    }
                </div>




            </DialogContent >

        </BootstrapDialog >
            <BootstrapDialog
                onClose={handleClosethank}
                aria-labelledby="customized-dialog-title"
                open={openthank} className="newotp-sec thanksuou"
            >


                <DialogContent dividers>
                    <div class="success-animation">
                        <svg class="checkmark" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" /><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>
                    <h2>Thank You !!</h2>
                    <p className="thanks-para">Your application is currently under review, some one from the HR may get in touch with you</p>


                </DialogContent >

            </BootstrapDialog >
        </>
    )
}

export default Jobdetails;
